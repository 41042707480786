<template>
  <v-footer app :inset="footer.inset">
    <v-col class="lighten-2 py-0 text-center" cols="12">
      <span class="px-4">&copy; {{ new Date().getFullYear() }}. Todos los derechos reservados </span>
    </v-col>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      footer: {
        inset: true,
      },
    }),
  }
</script>